import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { getInitials } from '../../utils/common';

export default function MerchantTransferChooseMerchant({
    merchantList,
    handleMerchantSelect,
    client,
}) {
    const [searchText, setSearchText] = useState('');
    const [filteredMerchants, setFilteredMerchants] = useState(merchantList);

    useEffect(() => {
        const filteredMerchants = merchantList.filter(merchant =>
            merchant.merchantName.toLowerCase().includes(searchText.toLowerCase()),
        );
        setFilteredMerchants(filteredMerchants);
    }, [searchText, client]);

    return (
        <div className="h-[100%] max-h-[80vh]">
            <p className="lg:text-2xl text-lg font-semibold relative flex">Merchants</p>
            <div className="flex flex-col items-center justify-between w-full">
                <p className="mt-[12px] text-[16px] text-[#51525C]">
                    To transfer funds to a merchant, please select one from the list below.
                </p>
                <Input
                    placeholder="Search Merchants"
                    suffix={<SearchOutlined />}
                    className="w-full p-2 mt-[24px]"
                    value={searchText}
                    onChange={e => setSearchText(e.target.value)}
                />
                {filteredMerchants && filteredMerchants.length === 0 ? (
                    <div className="flex items-center justify-center w-full h-full">
                        <p className="text-[16px] text-[#51525C]">No Merchants Found</p>
                    </div>
                ) : (
                    <div className="flex flex-col gap-[12px] w-full mt-[24px] h-[65vh] md:h-[300px] overflow-y-scroll">
                        {filteredMerchants.map(merchant => {
                            const logoUrl =
                                merchant.merchantLogo ||
                                `https://via.placeholder.com/40x40.png?text=${getInitials(merchant.merchantName)}`;
                            return (
                                <div
                                    key={merchant?.merchantId}
                                    className="flex items-center justify-between w-full p-4 border border-[#E0E0E0] rounded-[8px] hover:border-[#18181B]"
                                    onClick={() => handleMerchantSelect(merchant)}>
                                    <div className="flex items-center">
                                        <img
                                            src={logoUrl}
                                            alt="Merchant Logo"
                                            className="w-10 h-10 rounded-full"
                                        />
                                        <p className="ml-4 text-[#51525C]">
                                            {merchant.merchantName}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
