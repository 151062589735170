import React, { useState } from 'react';
import { Button, Input, Modal, Select } from 'antd';
import { clientTransactionStatusMapping, keyMappings, statusCode } from '../../utils/constant';
import apiRequest from '../../utils/api';
import { CopyButton, getCurrencySymbol, toFixedTrunc } from '../../utils/common';
import { CryptoTransactionInfo } from './CryptoTransactionInfo';
import { useSelector } from 'react-redux';
import { ChevronBottomIcon } from '../../utils/Images';
import ThemeButton from '../common/ThemeButton';

const { TextArea } = Input;

const TransactionInfo = ({ transaction, onUpdate, statusText, statusClassName }) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [loading, setLoading] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const transactionId = transaction?.transactionId || null;

    const transactionDetails = Object.entries(transaction.transactionDetails[0])
        .filter(([key]) => key !== 'transactionFee')
        .map(([key, value]) => {
            let formattedValue = value;
            if (['fromAmount', 'amount', 'toAmount'].includes(key)) {
                const numericValue = parseFloat(value);
                if (!isNaN(numericValue)) {
                    formattedValue = numericValue.toFixed(2);
                }
            } else if (key === 'fxrate') {
                const numericValue = parseFloat(value);
                if (!isNaN(numericValue)) {
                    formattedValue = numericValue.toFixed(4);
                }
            }

            return {
                type: keyMappings[key],
                value: formattedValue,
                showCopy: true,
            };
        });

    if (transaction) {
        const transactionFeeValue = transaction.transactionFee || 0;

        transactionDetails.push({
            type: 'Transaction Fee',
            value: transactionFeeValue,
            showCopy: true,
        });

        if (transaction.transferInFee) {
            transactionDetails.push({
                type: 'Merchant Fee',
                value: transaction.transferInFee,
                showCopy: true,
            });
        }
    }

    const statusLabelMap = {
        processing: {
            label: 'Processing',
            apiCode: statusCode['processing'][0],
        },
        complete: {
            label: 'Complete',
            apiCode: statusCode['completed'][0],
        },
        rejected: {
            label: 'Reject',
            apiCode: statusCode['rejected'][0],
        },
    };

    const updateTransaction = async () => {
        const body = {
            adminRole: localStorage.getItem('adminRole'),
            email: localStorage.getItem('email'),
            transactionData: transaction,
            message,
            status: statusLabelMap[status].apiCode,
            client: transaction.clientId,
        };
        setLoading(true);
        try {
            const response = await apiRequest('/update-client-transaction-merchant', 'POST', body);

            if (response.success) {
                setConfirmationModal(false);
                setMessage('');
                await onUpdate();
            } else {
                message.error(response.error);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error posting data:', error);
        }
    };

    return (
        <div className="bg-white p-5">
            <h1 className="font-semibold text-base pb-5 lg:hidden ">Transaction Info</h1>
            {transaction.currencyType === 'fiat' ? (
                <div className="p-3 pb-0 border rounded-lg border-[#E4E4E7] mt-5 bg-[#fcfcfc]">
                    <div className="flex justify-between items-center border-b border-[#E4E4E7] p-3">
                        <p className="text-sm font-normal text-darkGray" data-e2e="status">
                            Transaction status:
                        </p>
                        {['complete', 'rejected'].includes(
                            clientTransactionStatusMapping[transaction.status.code],
                        ) ? (
                            <>
                                {clientTransactionStatusMapping[transaction.status.code] ===
                                'complete' ? (
                                    <p
                                        data-e2e="completed"
                                        className="text-success bg-[#ECFDF3] rounded-full py-1 px-2 text-sm font-medium">
                                        Completed
                                    </p>
                                ) : (
                                    <p
                                        data-e2e="rejected"
                                        className="text-warning bg-[#FFFAEB] rounded-full py-1 px-2 text-sm font-medium">
                                        Rejected
                                    </p>
                                )}
                            </>
                        ) : (
                            <Select
                                suffixIcon={<img src={`${imageBaseUrl}${ChevronBottomIcon}`} />}
                                placeholder="Update status"
                                onSelect={() => setConfirmationModal(true)}
                                style={{ width: 170 }}
                                data-e2e="update-status-drop-down"
                                onChange={value => setStatus(value)}
                                options={[
                                    { value: 'processing', label: 'Processing' },
                                    { value: 'complete', label: 'Complete' },
                                    { value: 'rejected', label: 'Reject' },
                                ]}
                            />
                        )}
                    </div>
                    {transactionId && (
                        <div className="flex justify-between items-center flex-wrap border-b border-[#E4E4E7] p-3">
                            <p className="text-sm font-normal text-darkGray">Transaction ID:</p>
                            <p className="text-sm font-normal text-darkGray">
                                {transactionId}
                                <CopyButton msg={transactionId} />
                            </p>
                        </div>
                    )}

                    {transactionDetails.map(
                        (item, index) =>
                            item.type && (
                                <div
                                    className="flex justify-between items-center flex-wrap border-b border-[#E4E4E7] p-3"
                                    key={index}>
                                    <p className="text-sm font-normal text-darkGray">
                                        {item.type}:
                                    </p>
                                    <p className="text-sm font-normal text-darkGray">
                                        {item.value || '-'}
                                        {item.showCopy && <CopyButton msg={item.value} />}
                                    </p>
                                </div>
                            ),
                    )}
                </div>
            ) : (
                <CryptoTransactionInfo
                    transaction={transaction}
                    statusText={statusText}
                    statusClassName={statusClassName}
                />
            )}
            {confirmationModal && (
                <Modal
                    centered
                    title={
                        <p
                            className="capitalize text-2xl font-semibold text-primary"
                            data-e2e={statusLabelMap[status].label}>
                            {statusLabelMap[status].label}
                        </p>
                    }
                    open={confirmationModal}
                    footer={null}
                    onCancel={() => setConfirmationModal(false)}>
                    <p className="mt-10 mb-2 text-base">Note: </p>
                    <TextArea
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        className="px-4 py-3 bg-[#F4F4F5] border border-[#E4E4E7] rounded-lg"
                        rows={4}
                        placeholder="Add your note... "
                        data-e2e="add-note..."
                    />
                    <div className="mt-12 flex justify-end">
                        <div className="w-40">
                            <ThemeButton
                                loading={loading}
                                disabled={message === ''}
                                type="primary"
                                text="Update"
                                dataE2e="update-btn"
                                action={updateTransaction}
                                shape="round"
                            />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default TransactionInfo;
