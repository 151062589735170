import React, { useEffect, useState } from 'react';
import { Freeze } from '../../utils/Images';
import { useSelector } from 'react-redux';
import { Button, Form, Table } from 'antd';
import Loading from '../common/Loading';
import ConfirmBalanceChangeModal from '../modals/ConfirmBalanceChangeModal';
import ChangeBalanceForm from '../modals/ChangeBalanceForm';
import ThemeButton from '../common/ThemeButton';
import { CaretUpOutlined, CaretDownOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { useWindowWidth } from '../../utils/common';
import { getCryptoNameBySymbol } from '../../utils/common';
import { toFixedTrunc } from '../../utils/common';

const CryptoBalancesTab = () => {
    const [form] = Form.useForm();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const cryptoBalances = useSelector(state => state.account.cryptoBalancesData);
    const [balanceLoading, setBalanceLoading] = useState(false);
    const [selectedBalance, setSelectedBalance] = useState({});
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openChangeModal, setOpenChangeModal] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const windowWidth = useWindowWidth();
    const [columns, setColumns] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const handleOpenModal = currencyShortName => {
        const balance = cryptoBalances.find(item => item?.currencyShortName === currencyShortName);
        setSelectedBalance(balance);
        setOpenConfirmModal(true);
    };

    const handleModalClose = () => {
        setOpenConfirmModal(false);
        setOpenChangeModal(false);
    };

    const handleOpenChangeModal = () => {
        setOpenChangeModal(true);
        setOpenConfirmModal(false);
    };

    const fetchData = async () => {
        setBalanceLoading(true);
        try {
            const data = await Promise.all(
                cryptoBalances.map(async (item, index) => {
                    return {
                        key: (index + 1).toString(),
                        currencyType: {
                            icon: item?.currencyIcon,
                            currencyName: `${item?.currencyFullName} (${item?.currencyShortName})`,
                        },
                        usdAmount: `$ ${item?.convertedUSDAmount ? toFixedTrunc(item?.convertedUSDAmount, 2) : 0}`,
                        cryptoBalances: {
                            balance: item?.balanceAmount,
                            currencyShortName: item?.currencyShortName,
                        },
                    };
                }),
            );
            setDataSource(data);
            setBalanceLoading(false);
        } catch (error) {
            setBalanceLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const createColumns = isMobile => {
        const commonColumns = [
            {
                title: 'Currency',
                dataIndex: 'currencyType',
                key: 'currencyType',
                width: isMobile ? '80%' : '50%',
                render: data => (
                    <div className="flex items-center">
                        <img
                            src={`${imageBaseUrl}${data.icon}`}
                            alt="crypto-logo"
                            className="w-[30px] h-[30px] object-cover"
                        />
                        <span className="ml-4">
                            {getCryptoNameBySymbol(data?.currencyName)}
                            {data?.currencyName}
                        </span>
                    </div>
                ),
            },
            {
                title: 'Crypto Balances',
                dataIndex: 'cryptoBalances',
                key: 'cryptoBalances',
                width: isMobile ? '10%' : '25%',
                render: data => (
                    <div className="flex items-center">
                        <Button
                            // onClick={() => handleOpenModal(data?.currencyShortName)}
                            className="p-2 h-full w-[148px] flex justify-between">
                            <p>{data?.balance ? toFixedTrunc(data?.balance, 6) : 0}</p>
                            <p className="flex justify-between items-center gap-2">
                                {data.currencyShortName}
                                <span className="flex flex-col gap-[1px]">
                                    <CaretUpOutlined className="text-[#A0A0AB] w-2 h-1" />
                                    <CaretDownOutlined className="text-[#A0A0AB] w-2 h-1" />
                                </span>
                            </p>
                        </Button>
                        <Button
                            className="p-2 h-full ml-[8px] hidden lg:block flex-shrink-0"
                            onClick={''}>
                            <img
                                src={`${imageBaseUrl}${Freeze}`}
                                alt="freeze"
                                className="w-4 h-4"
                            />
                        </Button>
                    </div>
                ),
            },
            Table.EXPAND_COLUMN,
        ];

        if (!isMobile) {
            commonColumns.splice(1, 0, {
                title: 'USD',
                dataIndex: 'usdAmount',
                key: 'usdAmount',
                render: data => <p className="hidden md:block">{data}</p> || 0,
                width: '25%',
            });
        }

        return commonColumns;
    };
    const handleResize = () => {
        const isMobile = windowWidth < 1024;
        setColumns(createColumns(isMobile));
    };

    useEffect(() => {
        handleResize();
    }, [windowWidth]);

    const onExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRowKeys([...expandedRowKeys, record?.key]);
        } else {
            setExpandedRowKeys(expandedRowKeys.filter(key => key !== record?.key));
        }
    };

    const ExpandedRowItem = record => {
        const { label, image, itemText } = record;
        return (
            <div className="flex justify-between">
                <p className="text-[#51525C]">{label}</p>
                <div>
                    <p className="flex items-center">
                        {image && (
                            <img src={`${imageBaseUrl}${image}`} alt="logo" className="w-6 h-6" />
                        )}
                        <span className="ml-4">{itemText}</span>
                    </p>
                </div>
            </div>
        );
    };

    const expandableConfig = {
        expandedRowRender: record => (
            <div className="flex flex-col gap-2 border-[1px] rounded-lg p-3">
                <ExpandedRowItem
                    label="Currency:"
                    image={record?.currencyType?.icon}
                    itemText={record?.currencyType?.currencyName}
                />
                <ExpandedRowItem label="Balances in USD:" itemText={record?.usdAmount} />
                <hr className="my-2" />
                <ThemeButton
                    text="Freeze"
                    style="mr-3 w-24"
                    autoWidth={true}
                    icon={<img src={`${imageBaseUrl}${Freeze}`} alt="freeze" className="w-4 h-4" />}
                />
            </div>
        ),
        onExpand,
        expandedRowKeys,
        expandIcon: ({ onExpand, expanded, record }) => (
            <span
                onClick={e => {
                    onExpand(record);
                    e.stopPropagation();
                }}>
                {expanded ? (
                    <UpOutlined className="block lg:hidden" />
                ) : (
                    <DownOutlined className="block lg:hidden" />
                )}
            </span>
        ),
    };

    return (
        <>
            <div className="lg:pt-8 bg-white">
                <div className="flex items-center justify-between pt-4">
                    <h1 className="text-lg font-semibold">The Accounts displayed balances</h1>
                    <ThemeButton
                        text="Freeze all"
                        style="mr-3"
                        autoWidth={true}
                        icon={
                            <img
                                src={`${imageBaseUrl}${Freeze}`}
                                alt="freeze"
                                className="w-4 h-4"
                            />
                        }
                    />
                </div>
                {balanceLoading ? (
                    <div className="flex flex-col gap-5">
                        <Loading />
                    </div>
                ) : (
                    <div className="mt-6">
                        <Table
                            className="crypto-balance"
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                            expandable={expandableConfig}
                        />
                    </div>
                )}
            </div>
            {openConfirmModal && (
                <ConfirmBalanceChangeModal
                    open={openConfirmModal}
                    title="Are you sure?"
                    balance={selectedBalance}
                    handleClose={handleModalClose}
                    handleChange={handleOpenChangeModal}
                />
            )}
            {openChangeModal && (
                <ChangeBalanceForm
                    form={form}
                    title="Edit Balance"
                    balance={selectedBalance}
                    handleClose={handleModalClose}
                    footerText="Save"
                    open={openChangeModal}
                />
            )}
        </>
    );
};

export default CryptoBalancesTab;
