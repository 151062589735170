import { Button, Checkbox, Input, Modal, Tag, message } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import apiRequest from '../../utils/api';
import { LoadingSkeleton } from '../common/Loading';
import ThemeButton from '../common/ThemeButton';

const LinkMerchantModal = ({ clientId, open, onClose, onSave, onReset }) => {
    const [loading, setLoading] = useState(false);
    const email = localStorage.getItem('email');
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState([]);

    const [selectedMerchants, setSelectedMerchants] = useState([]);

    const handleChange = event => {
        const userId = event.target.name;
        setSelectedMerchants(prev => {
            if (event.target.checked) {
                return [...prev, userId];
            } else {
                return prev.filter(id => id !== userId);
            }
        });
    };

    function handleOnClose() {
        setSearchText('');
        onClose();
    }

    const getMerchants = async () => {
        setLoading(true);
        try {
            const body = {
                clientId: clientId,
                notLinked: true,
                email,
                limit: 200,
                searchQuery: searchText,
            };
            const res = await apiRequest('/fetch-merchant', 'POST', body);
            if (res.success) {
                setData(res.data.merchants);
            } else {
                message.error(res.error);
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const getData = setTimeout(() => {
            if (open) {
                getMerchants();
            }
        }, 500);
        return () => {
            clearTimeout(getData);
            setSelectedMerchants([]);
        };
    }, [searchText, open]);

    return (
        <Modal
            title={<p className="text-2xl font-semibold">Link Merchants</p>}
            open={open}
            centered
            okText="Continue"
            className={'common-mobile-view merchant-mobile-view'}
            okButtonProps={{ className: 'rounded-full px-8 py-3 h-[46px]' }}
            cancelButtonProps={{ className: 'rounded-full px-8 py-3 h-[46px]' }}
            onOk={() => onSave(selectedMerchants)}
            onCancel={handleOnClose}
            footer={
                <div className="grid grid-cols-[1fr_2fr] gap-[30px] sm:gap-[70px]">
                    <ThemeButton
                        style="!w-[140px]"
                        key="reset"
                        shape="round"
                        action={onReset}
                        text="Reset"
                    />
                    <div className="grid grid-cols-2 gap-[10px]">
                        <ThemeButton
                            style="w-[140px]"
                            key="cancel"
                            action={handleOnClose}
                            text="Cancel"
                            shape="round"
                        />
                        <ThemeButton
                            loading={loading}
                            style="!w-[140px]"
                            key="submit"
                            type="primary"
                            shape="round"
                            text="Continue"
                            action={() => onSave(selectedMerchants)}
                        />
                    </div>
                </div>
            }>
            <div className="h-[78vh] md:h-auto overflow-auto hide-scrollbar">
                <p className="text-base text-[#51525C]">
                    Select or search one of multiple merchant you would like to link to this
                    merchant.
                </p>
                <Input
                    onChange={e => setSearchText(e.target.value)}
                    value={searchText}
                    placeholder="Search Merchant, Merchant ID"
                    suffix={<SearchOutlined />}
                    className=" p-2 my-6"
                />
                {loading ? (
                    <div className="border border-[#D1D1D6] rounded-md h-[50vh] md:h-[300px]">
                        <LoadingSkeleton />
                    </div>
                ) : (
                    <>
                        <div className="!px-0 filter-tags overflow-hidden mb-6">
                            {data
                                .filter(user => selectedMerchants.includes(user._id))
                                .map(u => (
                                    <Tag
                                        bordered={false}
                                        closable
                                        key={u._id}
                                        onClose={() =>
                                            setSelectedMerchants(prev => {
                                                return prev.filter(id => id !== u._id);
                                            })
                                        }
                                        className="text-[14px] transaction-tag flex font-medium">
                                        <div>
                                            <p>{u.merchantName}</p>
                                        </div>
                                    </Tag>
                                ))}

                            {selectedMerchants.length > 0 && (
                                <div
                                    className="text-[#1570EF] text-[14px] px-2 font-medium cursor-pointer"
                                    onClick={onReset}>
                                    Reset all ({selectedMerchants.length})
                                </div>
                            )}
                        </div>
                        <div className="border border-[#D1D1D6] rounded-md h-[50vh] md:h-[300px] overflow-scroll hide-scrollbar">
                            {data.length > 0 ? (
                                data.map(user => (
                                    <div
                                        key={user._id}
                                        className="flex justify-between p-2.5 pr-6 ">
                                        <p className="text-sm text-primary">
                                            {user.merchantName}{' '}
                                            <span className="text-[#70707B]">({user._id})</span>
                                        </p>
                                        <Checkbox
                                            checked={selectedMerchants.includes(user._id)}
                                            name={user._id}
                                            onChange={handleChange}
                                        />
                                    </div>
                                ))
                            ) : (
                                <div className=" p-2.5 pr-6">
                                    <p className="theme-table-empty">No data found!</p>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default LinkMerchantModal;
