import { Tooltip } from 'antd';
import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BTC, Copy, ETH, LTC, USDT } from './Images';
import moment from 'moment';
import apiRequest from './api';
import * as actions from '../redux/actions/accountActions';
import CopyIcon from '../components/icons/CopyIcon';
import { statusCode } from './constant';

export const useWindowWidth = () => {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

export function toFixedTrunc(x, n = 2) {
    let xFixed = x || 0;
    if (xFixed.toString().includes('e')) {
        xFixed = xFixed.toLocaleString('fullwide', { useGrouping: false });
    }
    if (xFixed === 0) {
        return xFixed;
    }

    const v = (typeof xFixed === 'string' ? xFixed : xFixed.toString()).split('.');
    if (n <= 0) {
        return v[0];
    }
    let f = v[1] || '';
    let formatV = v[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (f.length > n) {
        return `${formatV}.${f.substr(0, n)}`;
    }
    if (f === '' && (n === 2 || n === 4)) {
        return `${formatV}.${'0'.repeat(n)}`;
    }
    if (f.length === 1) {
        f = f + '0';
    }
    if (f.length == 0) {
        return `${formatV}`;
    } else {
        return `${formatV}.${f}`;
    }
}

export const getBalanceSign = data => {
    const value = data.type;
    switch (value) {
        case 'Deposit':
        case 'Buy':
            return '+ ';
        case 'Withdrawal':
        case 'Payment Out':
        case 'Sell':
        case 'Send':
            return '- ';
        case 'Transfer':
            return data.transferInOut === 'In' ? '+ ' : '- ';
        default:
            return '';
    }
};
export const cryptoCurrencyIcon = {
    BTC,
    USDT,
    LTC,
    ETH,
};
export const getCurrencySymbol = shortName => {
    switch (shortName) {
        case 'EUR':
            return '€';
        case 'USD':
            return '$';
        case 'GBP':
            return '£';
        case 'AUD':
            return 'A$';
        case 'CAD':
            return 'C$';
        case 'AED':
            return 'د.إ';
        case 'CHF':
            return '₣';
        case 'TRY':
            return '₺';
        case 'HKD':
            return 'HK$';
        case 'SGD':
            return 'S$';
        case 'MXN':
            return 'MX$';
        case 'JPY':
            return '¥';
        case 'CNY':
            return 'CN¥';
        case 'THB':
            return '฿';
        case 'BTC':
            return '₿';
        case 'USDC':
            return 'USDC';
        case 'USDT':
            return '₮';
        case 'LTC':
            return 'Ł';
        case 'ETH':
            return 'Ξ';
        default:
            return '';
        case 'ILS':
            return '₪';
    }
};

export const getStatusComponent = status => {
    switch (status) {
        case 'PrivateClient:CreateTransaction':
        case 'PrivateClient:ClientBalanceUpdated':
        case 'PrivateClient:DepositDetail':
        case 'PrivateClient:FXDetail':
        case 'PrivateClient:PaymentOutDetail':
        case 'PrivateClient:TransactionApprovedBy':
        case 'PrivateClient:TransferDetail':
        case 'PrivateClient:WithdrawalDetail':
        case 'BuyFlow:InitiateRequest':
        case 'BuyFlow:ValidateRequest':
        case 'BuyFlow:FiatUpdateBalance':
        case 'BuyFlow:CryptoUpdateBalance':
        case 'BuyFlow:SubmitCaasBuyRequest':
        case 'BuyFlow:CaasCallbackReceived':
        case 'BuyFlow:Notification':
        case 'SellFlow:InitiateRequest':
        case 'SellFlow:ValidateRequest':
        case 'SellFlow:FiatUpdateBalance':
        case 'SellFlow:CryptoUpdateBalance':
        case 'SellFlow:SubmitCaasSellRequest':
        case 'SellFlow:CaasCallbackReceived':
        case 'SellFlow:Notification':
        case 'SendFlow:InitiateRequest':
        case 'SendFlow:ValidateRequest':
        case 'SendFlow:UpdateCryptoBalance':
        case 'SendFlow:SubmitCaasSendRequest':
        case 'SendFlow:CaasCallbackReceived':
        case 'SendFlow:Notification':
        case 'ReceiveFlow:InitiateRequest':
        case 'ReceiveFlow:CryptoUpdateBalance':
        case 'ExchangeFlow:InitiateRequest':
        case 'ExchangeFlow:ValidateRequest':
        case 'ExchangeFlow:UpdateFromCryptoBalance':
        case 'ExchangeFlow:UpdateToCryptoBalance':
        case 'ExchangeFlow:SubmitCaasExchangeRequest':
        case 'ExchangeFlow:CaasCallbackReceived':
        case 'ExchangeFlow:Notification':
            return (
                <div className="bg-[#F4F4F5] lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px]  font-medium text-[#3F3F46] ">
                    Processing
                </div>
            );
        case 'PrivateClient:TransactionCompleted':
        case 'BuyFlow:BuyCompleted':
        case 'SellFlow:SellCompleted':
        case 'SendFlow:SendCompleted':
        case 'ReceiveFlow:ReceiveCompleted':
        case 'ExchangeFlow:ExchangeCompleted':
            return (
                <div className="bg-[#ECFDF3] lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px]  font-medium text-[#027A48] ">
                    Completed
                </div>
            );
        case 'PrivateClient:Rejected':
        case 'SendFlow:TransactionRejected':
        case 'ExchangeFlow:ExchangeFailed':
        case 'ReceiveFlow:TransactionRejected':
        case 'BuyFlow:TransactionRejected':
        case 'SellFlow:TransactionRejected':
            return (
                <div className="bg-[#FFFAEB] lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px]  font-medium text-[#B54708] ">
                    Rejected
                </div>
            );
        case 'PrivateClient:ErrorEncountered':
        case 'BuyFlow:ErrorEncountered':
        case 'SellFlow:ErrorEncountered':
        case 'SendFlow:ErrorEncountered':
        case 'ReceiveFlow:ErrorEncountered':
        case 'ExchangeFlow:ErrorEncountered':
            return (
                <div className="bg-[#FEF3F2] lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px]  font-medium text-[#B42318] ">
                    Failed
                </div>
            );
        case 'PrivateClient:RequestForApproval':
        case 'SendFlow:CaasSendCryptoNotification':
            return (
                <div className="bg-[#F4F4F5] lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px]  font-medium text-[#3F3F46]">
                    Pending
                </div>
            );
        default:
            return (
                <div className="bg-[#EFF8FF] lg:py-1 py-[2px] lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px]  font-medium text-[#175CD3]">
                    Processing
                </div>
            );
    }
};
export const CopyButton = ({ msg }) => {
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const [text, setText] = useState('copy');

    const handleCopy = () => {
        navigator.clipboard.writeText(msg).then(() => {
            setText('copied!');
        });
        setTimeout(() => {
            setText('copy');
        }, 1000);
    };

    return (
        <Tooltip placement="top" title={text}>
            <span onClick={handleCopy} className="inline-block ml-2 cursor-pointer copy-icon">
                <CopyIcon />
            </span>
        </Tooltip>
    );
};

export const formatAmount = amount => {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(amount);
};

export const getDate = filter => {
    const now = moment();

    switch (filter) {
        case '1D':
            return now.subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ');
        case '2W':
            return now.subtract(2, 'week').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        case '3M':
            return now.subtract(3, 'month').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        case '6M':
            return now.subtract(6, 'month').startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');
        case 'all':
            return 'all';
        default:
            return now;
    }
};

/**
 * Makes an API request to get presigned URL.
 *
 * @param {Object} [payload] - The payload object for presigne URL API.
 * @param {Object} [selectedFile] - Selected file to upload.
 * @param {String} [imgUrl] - Image string.
 * @returns {Promise<String>} This will return the image URL as string.
 */
export const getPresignedUrl = async (payload, selectedFile, imgUrl) => {
    try {
        const res = await apiRequest('/get-client-presigned-url', 'POST', payload);
        const signedUrl = res.data.data[0];
        await fetch(signedUrl, {
            method: 'PUT',
            body: selectedFile,
            headers: {
                'Content-Type': `image/${selectedFile.type}`,
                'cache-control': 'public, max-age=0',
            },
        });

        return imgUrl;
    } catch (err) {
        console.log('Error in getPresignedUrl: ', err);
        throw err;
    }
};

export const getBalancesData = async (dispatch, clientId, email, clientEmail, setLoading) => {
    setLoading(true);
    const request = {
        clientId: clientId,
        email,
        clientEmail: clientEmail,
    };
    const response = await apiRequest('/get-client-balances', 'POST', request);
    if (response.success) {
        dispatch(actions.setBalancesData(response.data));
    }
    setLoading(false);
};

export const getBeneficiaryList = async (dispatch, email, clientEmail) => {
    const response = await apiRequest('/get-client-beneficiary', 'POST', {
        email,
        clientEmail: clientEmail,
    });
    if (response.success) {
        dispatch(actions.setAccountsData(response.data));
    }
};

export const mapCryptoToBlockchain = cryptoShortName => {
    switch (cryptoShortName) {
        case 'BTC':
            return 'Bitcoin';
        case 'ETH':
        case 'USDC':
        case 'USDT':
        case 'Dai':
            return 'Ethereum';
        case 'LTC':
            return 'Litecoin';
        case 'TRC20-USDT':
            return 'Tron';
        default:
            return '';
    }
};
export const numberRule = /^(\d+)((.{1}\d+)?)$/;
export const numberRuleZero = /^(?:0)[0-9]/;

export function validateAmount(value, fee, selectedCurrency, balancesData) {
    if (selectedCurrency && selectedCurrency[0]) {
        const balance = balancesData.find(
            x =>
                x.currencyShortName ===
                (Array.isArray(selectedCurrency) ? selectedCurrency[0] : selectedCurrency),
        );

        const transactionFee = fee ? (Number(value) * fee.percentFee) / 100 : 0;
        const finalBalance = Number(value) + transactionFee;

        if (!value) {
            return '* Amount field is required';
        }
        if (numberRuleZero.test(value)) {
            return '* Please, fill the amount value correctly';
        }
        if (!numberRule.test(value)) {
            return '* Please, fill the amount value correctly';
        }
        if (Number(value) <= 0) {
            return '* Amount must be greater than 0';
        }
        if (balance.balanceAmount === 0) {
            return '* Please adjust the value as you have an insufficient balance';
        }
        if (balance && finalBalance > balance.balanceAmount) {
            return transactionFee !== 0
                ? '* Please adjust the value to take into account any fees payable for this transaction'
                : '* Please adjust the value as you have an insufficient balance';
        } else {
            return '';
        }
    }
}

export function removeCommas(inputString) {
    return inputString.length > 0 && inputString.replace(/,/g, '');
}

export const getCryptoFee = async feeData => {
    const request = {
        ...feeData,
    };
    const response = await apiRequest('/get-crypto-fee', 'POST', request);
    if (response.success) {
        return response.data;
    } else {
        console.error();
    }
};
export const getCryptoBalances = async (dispatch, id, paymentProviderId, email) => {
    const request = {
        clientId: id,
        paymentProviderId,
        email,
    };
    const response = await apiRequest('/get-crypto-balance', 'POST', request);
    if (response.success) {
        dispatch(actions.setCryptoBalancesData(response.data));
        return response.data;
    } else {
        console.error();
    }
};
export const handleCryptoExchangeRate = async exchangeData => {
    const request = {
        ...exchangeData,
    };
    const response = await apiRequest('/crypto-exchange-rate', 'POST', request);
    if (response.success) {
        return response.data;
    } else {
        console.error();
    }
};

export const useCommonFunctions = () => {
    const email = localStorage.getItem('email');
    const paymentProviderId = localStorage.getItem('paymentProviderId');
    const { clientData } = useSelector(state => state.account);
    const dispatch = useDispatch();

    const getCryptoBalances = async () => {
        const body = {
            clientId: clientData?.clientId,
            paymentProviderId,
            email,
        };
        try {
            const response = await apiRequest('/get-crypto-balance', 'POST', body);

            if (response?.success) {
                if (response?.data) {
                    dispatch(actions.setCryptoBalancesData(response.data));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return {
        getCryptoBalances,
    };
};

export const getClientTransactionStatusMapping = () => {
    const mapping = {};

    Object.entries(statusCode).forEach(([statusKey, codes]) => {
        codes.forEach(code => {
            mapping[code] = statusKey;
        });
    });

    return mapping;
};

export const getInitials = name => {
    return name
        .split(' ')
        .slice(0, 2)
        .map(word => word[0])
        .join('')
        .toUpperCase();
};

export const getCryptoNameBySymbol = shortName => {
    let newShortName = shortName.trim();
    newShortName = newShortName.replace(/^\s*\(\s*(.*?)\s*\)\s*$/, '$1');
    newShortName = newShortName.replace(/\s+/g, ' ');

    switch (newShortName) {
        case 'BTC' || '(BTC)':
            return 'Bitcoin';
        case 'ETH':
            return 'Ethereum';
        case 'DAI':
            return 'DAI';
        case 'USDT':
            return 'Tether';
        case 'USDC':
            return 'USDC';
        case 'TRX':
            return 'Tron';
        case 'LTC':
            return 'Litecoin';
        default:
            return '';
    }
};
const blockExplorersTransactionsLinks = {
    bitcoin: {
        mainnet: 'https://www.blockchain.com/explorer/transactions/btc/',
        testnet: 'https://blockstream.info/testnet/tx/',
    },
    ethereum: {
        mainnet: 'https://etherscan.io/tx/',
        testnet: 'https://sepolia.etherscan.io/tx/',
    },
    litecoin: {
        mainnet: 'https://blockchair.com/litecoin/transaction/',
        testnet: 'https://live.blockcypher.com/ltc-testnet/',
    },
    ripple: {
        mainnet: 'https://xrpscan.com/ledger/',
        testnet: 'https://test.bithomp.com/',
    },
    tron: {
        mainnet: 'https://tronscan.org/#/transaction/',
        testnet: 'https://shasta.tronscan.org/#/transaction/',
    },
};

const blockExplorersAddressesLinks = {
    bitcoin: {
        mainnet: 'https://www.blockchain.com/explorer/addresses/btc/',
        testnet: 'https://blockstream.info/testnet/address/',
    },
    ethereum: {
        mainnet: 'https://etherscan.io/address/',
        testnet: 'https://sepolia.etherscan.io/address/',
    },
    litecoin: {
        mainnet: 'https://blockchair.com/litecoin/address/',
        testnet: 'https://blockchair.com/litecoin/address/',
    },
    ripple: {
        mainnet: 'https://xrpscan.com/account/',
        testnet: 'https://test.bithomp.com/',
    },
    tron: {
        mainnet: 'https://tronscan.org/#/address/',
        testnet: 'https://shasta.tronscan.org/#/address/',
    },
};

export const getExplorerLink = (blockchain, network, hashOrAddress, isHash) => {
    const lowerCaseBlockchain = blockchain && blockchain.toLowerCase();
    const lowerCaseNetwork = network && network.toLowerCase();
    if (!lowerCaseBlockchain || !lowerCaseNetwork) {
        return '';
    }
    let explorerLink = '';
    if (isHash) {
        explorerLink = blockExplorersTransactionsLinks[lowerCaseBlockchain][lowerCaseNetwork];
    } else {
        explorerLink = blockExplorersAddressesLinks[lowerCaseBlockchain][lowerCaseNetwork];
    }
    return `${explorerLink}${hashOrAddress}`;
};
